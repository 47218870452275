import React from "react";
import Landing from "./screens/Landing.jsx";

export default function App() {
  return (
    <>
      <Landing />
    </>
  );
}

